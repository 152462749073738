/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
       background-color:white; font-size: 12pt;
}
.token-form {
    text-align:center;
}

.mat-radio-label-content {
  min-height: 60px;
}
.mat-radio-container {
  top: -20px;
}


.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: aliceblue;
}

.title-toolbar {
  box-shadow: 3px 0px 3px 3px grey; 
}
.vaimg {
  height: 60px;
  margin-top:10px;
}
.logodiv {
  flex: 1 1 auto;
}
.menubtn {
 float:right;
}
.menubtndiv {
  flex: 1 1 auto;
}
.sitetitlediv {
    flex:1 1 auto;
    text-align:center;
    font-size:18pt;
    color:white;
}
.sitetitlediv a {
  color: white;
}
@media only screen and (max-device-width: 600px) {
  /* For mobile phones: */
  [class*="vaimg"] {
    height: 50px;
  }
}


.toolbar-spacer {
  flex: 1 1 auto;
}
.toolbar-spacer-2 {
  flex: 1 1 auto;
}

button {
  background-color: #064064;
  color: aliceblue;
  font-size: 16pt;
}

.mat-button {
  background-color: #064064;
  color: aliceblue;
  font-size: 16pt;
}

.mat-tab-label-active {
  background-color: white !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: solid 1px black !important;
  border-right: solid 1px black !important;
  border-left: solid 1px black !important;
  box-shadow: 5px 1px 5px 1px;
  margin-bottom: 0px !important;
  margin-right: 10px;
  margin-left: 12px;
  color: black !important;
  padding: 10px !important;
  height: 120px;
}


.mat-tab-label {
  background-color: #eeeeee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: solid 1px #c0c0c0;
  border-right: solid 1px #c0c0c0;
  border-left: solid 1px #c0c0c0;
  margin-bottom: 0px;
  margin-right: 10px;
  margin-left: 12px;
  padding: 10px !important;
}

.mat-tab-body-wrapper {
  border: solid 1px black;
  border-radius: 5px;
  padding: 10px;
}

.mat-ink-bar {
    margin-bottom:-10px;
}
